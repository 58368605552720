import { Swiper, Navigation, Pagination } from "swiper"
import "swiper/css"
import "swiper/css/pagination"

export default function initGallery() {
	const components = document.querySelectorAll(".gallery")
	components.forEach((component) => {
		const swiperNode = component.querySelector(".swiper")
		const buttonNext = component.querySelector(".swiper-button-next")
		const buttonPrev = component.querySelector(".swiper-button-prev")
		const pagination = component.querySelector(".swiper-pagination")

		const swiper = new Swiper(swiperNode, {
			modules: [Navigation, Pagination],
			centeredSlides: true,
			direction: "horizontal",
			slidesPerView: 1,
			loop: true,
			pagination: {
				el: pagination,
				type: "bullets",
				clickable: true,
			},
			navigation: {
				nextEl: buttonNext,
				prevEl: buttonPrev,
			},
		})
	})
}
