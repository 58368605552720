export const getCookie = (name: string): string | undefined => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
}

export const setCookie = (name, value, exDays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export const $id = (id: string): HTMLElement | null => document.getElementById(id);
export const $ = <T extends Element>(query: string, el: ParentNode = document.body): T | null => el.querySelector<T>(query);
export const $$ = <T extends Element> (query: string, el: ParentNode = document.body): NodeListOf<T> => el.querySelectorAll<T>(query);
