
import IntersectionObserver from 'intersection-observer-polyfill';

export default class InViewportController {

    observer: IntersectionObserver;
    selector: string = '[data-in-viewport]:not(.in-viewport)';
    options: IntersectionObserverInit = {
        rootMargin: '0px',
        threshold: 0.25
    };

    constructor() {
        this.observer = new IntersectionObserver(this.observe, this.options);
    }

    init(){
        const elements: NodeListOf<Element> = document.querySelectorAll(this.selector);
        elements.forEach(element=>{
            this.observer.observe(element);
        });
    }

    observe=(entries: IntersectionObserverEntry[], observer: IntersectionObserver)=> {
        for (let entry of entries) {
            let {target} = entry;
            target.classList.toggle('in-viewport', entry.isIntersecting);
            if (entry.isIntersecting) {
                if (target.getAttribute('data-in-viewport') !== 'continuous') {
                    observer.unobserve(entry.target);
                }
            }
        }
    }

}
